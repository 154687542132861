import "./HolidayHoursModal.css";

import { useState, useEffect } from "react";

const HolidayHoursModal = ({ callFunction }) => {
    const [showHolidayHoursModal, setHolidayHoursModal] = useState(true);

    const toggleModal = () => {
        setHolidayHoursModal(!showHolidayHoursModal);
        callFunction(false);
    }

    // event listener to close modal when clicked outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (event.target.className === "holiday-hours-modal") {
                setHolidayHoursModal(false);
                callFunction(false);
            }
        }

        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, );

    return (
        <>
            {showHolidayHoursModal &&
                <div id="holiday-hours-modal" className="holiday-hours-modal">
                    <div className="holiday-hours-modal-content">
                        <div className="holiday-hours-modal-header">
                            <h2>River City Cabinets Holiday Hours</h2>
                            <span className="holiday-hours-modal-close" onClick={toggleModal}>&times;</span>
                        </div>
                        <div className="holiday-hours-modal-body">
                            <p><b>New Year's Day:</b> Closed.</p>
                            <p><b>Martin Luther King Jr. Day:</b> Closed.</p>
                            <p><b>Presidents' Day:</b> Closed.</p>
                            <p><b>Memorial Day:</b> Closed.</p>
                            <p><b>Independence Day:</b> Closed.</p>
                            <p><b>Labor Day:</b> Closed.</p>
                            <p><b>Veterans Day:</b> Closed.</p>
                            <p><b>Thanksgiving Day:</b> Closed.</p>
                            <p><b>Christmas Day:</b> Closed.</p>
                        </div>
                        <div className="holiday-hours-modal-footer">
                            <h3>Contact us for any question</h3>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default HolidayHoursModal;