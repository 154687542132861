import './Banner.css';

import { useNavigate } from "react-router-dom";
// import backgroundVideo from "./imgs/flag.mp4";
import { useState } from "react";

import HolidayHoursModal from "../../components/Modal/HolidayHoursModal";

const Banner = () => {
    const [showHolidayHoursModal, setShowHolidayHoursModal] = useState(false);

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    const handleHolidayHoursModalClick = (e) => {
        e.preventDefault();
        setShowHolidayHoursModal(!showHolidayHoursModal);
    }

    return (
    <div className="banner" id="banner">
        {/* <video autoPlay muted loop id="myVideo">
            <source src={backgroundVideo} type="video/mp4" />
        </video> */}
        <div className="fourth">
            <h1 className="title">River City Cabinets</h1>
            <h2 className="subtitle">Cabinets and Flooring in Nampa, Meridian and Boise Area</h2>
            {/* <h2 className="subtitle">Turnaround rate 19 to 25 days!</h2> */}
            {/* <h3 className="description">Turnaround rate 19 to 25 days!</h3> */}
            <h4 className="turnaround">Turnaround rate 19 to 25 days!</h4>
            <h4 className="hours">Monday - Saturday: By appointment only</h4>
            <h4 id="holiday-hours-modal-open" className="holiday-hours" onClick={handleHolidayHoursModalClick}>Click here to check Holiday hours</h4>
            {showHolidayHoursModal && <HolidayHoursModal callFunction={setShowHolidayHoursModal} />}
            <div className="cta">
                <button className="primary-button" onClick={handleButtonClick}>Get Your Quote Now</button>
            </div>
        </div>
        {/* <div className="content">
            <div className="text">
                <div className="title">
                    <hr />
                    <h1>River City Cabinets</h1>
                </div>
                <div className="subtitle">
                    <h2 className="primary">RTA Cabinets and Flooring in Nampa and Boise Metropolitan Area</h2>
                    <h2 className="primary">Residential and Commercial Cabinets</h2>
                </div>
                <div className="list light-1">
                    <div className="item">
                        <hr /><h2>RTA Solid Wood Cabinets</h2>
                    </div>
                    <div className="item">
                        <hr /><h2>High End Kitchen Cabinets</h2>
                    </div>
                    <div className="item">
                        <hr /><h2>Built in Bathroom Cabinets</h2>
                    </div>
                    <div className="item">
                        <hr /><h2>LVP flooring</h2>
                    </div>
                </div>
                <div className="button-banner-container">
                    <button className="primary-button" onClick={handleButtonClick} >Contact</button>
                </div>
            </div>
        </div> */}
    </div>
    )
}

export default Banner;